<i18n>
ru:
  chooseButton: Выбрать подарок
  changeButton: Изменить подарок
  chooseBlockText: Подарок на день рождения
  birthdayGiftCouldBeHere: Здесь мог бы быть ваш подарок на день рождения
  birthdayGiftCouldBeHereContext: Но сегодня вы его уже получили. Попробуйте получить подарок завтра
ua:
  chooseButton: Вибрати подарунок
  changeButton: Змінити подарунок
  chooseBlockText: Подарунок на день народження
  birthdayGiftCouldBeHere: Подарунок на день народження
  birthdayGiftCouldBeHereContext: >-
    'Кількість подарунків в день обмежена. Будь ласка, спробуйте отримати
    подарунок завтра.'
us:
  chooseButton: Choose gift
  changeButton: Change gift
  chooseBlockText: Birthday gift
  birthdayGiftCouldBeHere: Birthday present
  birthdayGiftCouldBeHereContext: >-
    'The number of gifts per day is limited. Please, try to get your present
    tomorrow.'
</i18n>

<template>
  <div
    v-if="birthdayIsVisible"
    class="v-cart-item-birthday-wrapper v-py-sm"
  >
    <div
      class="v-cart-item-birthday v-d-flex v-flex-row-no-wrap"
      :class="{ 'v-cart-item-small': inSmallBasket }"
    >
      <div
        class="v-cart-item-birthday__image v-mr-sm"
        :class="{ 'v-cart-item-birthday__image__small_cart': inSmallBasket }"
      >
        <div
          v-if="selectedGift === null"
          class="v-d-flex v-align-items-center"
        >
          <icon-cart-gift-colored />
        </div>
        <div
          v-else
          class="image"
        >
          <arora-nuxt-image
            v-if="inSmallBasket"
            :alt="selectedGift.Product.Name"
            :height="100"
            :image="selectedGift.Product.SmallImageURL"
            :width="100"
            image-type="ProductSmall"
          />
          <arora-nuxt-image
            v-else
            :alt="selectedGift.Product.Name"
            :image="selectedGift.Product.SmallImageURL"
            image-type="ProductSmall"
          />
        </div>
      </div>

      <div class="v-cart-item-birthday__summary v-d-flex v-flex-column v-justify-content-center">
        <template v-if="isDayLimitReached">
          <div
            class="v-cart-item-birthday-title v-mb-sm"
            v-html="translate('birthdayGiftSelect.birthdayGiftCouldBeHere')"
          />
          <div
            class="v-cart-item-birthday-subtitle"
            v-html="translate('birthdayGiftSelect.birthdayGiftCouldBeHereContext')"
          />
        </template>
        <div
          v-else-if="selectedGift === null"
          class="v-cart-item-birthday-title"
          v-html="translate('birthdayGiftSelect.chooseBlockText')"
        />
        <template v-else>
          <div
            class="v-cart-item-birthday-title v-mb-sm"
            v-html="sanitize(selectedGift.Name)"
          />
          <div
            v-if="
              selectedGift.Product !== null &&
              !stringIsNullOrWhitespace(selectedGift.Product.Description)
            "
            class="v-cart-item-birthday-subtitle"
            v-html="sanitize(selectedGift.Product.Description)"
          />
          <div
            v-if="!stringIsNullOrWhitespace(selectedGift.ForbiddenReason)"
            class="v-cart-item-birthday-subtitle v-error-color"
            v-html="sanitize(selectedGift.ForbiddenReason)"
          />
        </template>
      </div>

      <div
        v-if="selectedGift"
        class="v-cart-item-birthday-delete"
        :class="{ 'v-cart-item-birthday-delete__small_cart': inSmallBasket }"
        @click="async () => await deleteGift(selectedGift)"
      >
        <icon-general-cross />
      </div>
    </div>

    <div class="v-w-100 v-px-sm v-d-flex v-flex-row v-justify-content-end">
      <arora-button
        class-name="v-btn v-btn-link-secondary v-btn-text v-no-underline v-profile-field-input__change-btn"
        ignore-settings
        :label="
          translate(
            !selectedGift ? 'birthdayGiftSelect.chooseButton' : 'birthdayGiftSelect.changeButton'
          )
        "
        :disabled="isDayLimitReached"
        @click="async () => await chooseGift()"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { PresentInfo } from '~types/accountStore'
import type { CartItem } from '~types/clientStore'

import { useCommon } from '@arora/common'

defineProps<{
  inSmallBasket?: boolean
}>()

const accountStore = useAccountStore()
const clientStore = useClientStore()
const popupStore = usePopupStore()

const { sanitize, translate } = useI18nSanitized()
const { removeCartItem } = useCartFunctionsWithDialogs()
const { stringIsNullOrWhitespace } = useCommon()

const birthdayIsVisible = computed(() => {
  const birthdayData = accountStore.BirthdayGifts.data

  if (birthdayData === null) {
    return false
  }

  return (
    birthdayData.BirthdayGiftIsEnabled &&
    birthdayData.ClientDataFilled &&
    birthdayData.InBirthdayPeriod &&
    !birthdayData.AnnualLimitReached
  )
})

const selectedGift = computed<CartItem | null>(() => {
  const gifts = accountStore.BirthdayGifts.data?.Presents ?? null

  if (gifts === null) {
    return null
  }

  return (
    clientStore.ClientState?.data?.Cart?.Content?.find((item: CartItem) => {
      return gifts && gifts.some((gift: PresentInfo) => gift.ProductID === item.ProductID)
    }) ?? null
  )
})

onMounted(() => {
  if (import.meta.client) accountStore.initBirthdayGifts()
})

const isDayLimitReached = computed<boolean>(
  () => accountStore.BirthdayGifts.data?.DailyLimitReached ?? false
)

async function chooseGift(): Promise<void> {
  await popupStore.openPopup({
    popupClosable: true,
    popupName: 'cartBirthdayGiftPopup'
  })
}

async function deleteGift(item: CartItem | null): Promise<void> {
  if (item) {
    await removeCartItem(item.ID, item.Count, item.GroupID, item.Name)
  }
}
</script>

<style lang="scss">
@use '~/assets/variables';
@use '~/assets/mixins';

.v-cart-item-birthday {
  background: variables.$ProductCardBackground;
  color: variables.$ProductCardColor;
  border-radius: variables.$BorderRadius;
  border: variables.$BorderWidth solid variables.$BorderColor;
  padding: 15px;

  position: relative;
  margin-bottom: 1.1rem;

  .v-cart-item-birthday__image {
    flex: 0 0 165px;
    max-width: 165px;

    &__small_cart {
      flex: 0 0 60px;
      max-width: 6-px;
    }

    svg {
      width: 60px;
      height: 60px;
    }

    .image {
      box-shadow: variables.$CardShadow;
      border-radius: variables.$BorderRadius;
      overflow: hidden;
    }
  }

  .v-cart-item-birthday-title {
    font-size: 1.2rem;
    color: variables.$BodyTextColor;
  }

  .v-cart-item-birthday-subtitle {
    font-size: variables.$TextSizeMain;
    color: variables.$BodyTextColorLight;
  }

  .v-cart-item-birthday-delete {
    position: absolute;
    top: -5px;
    right: -5px;
    background-color: variables.$ErrorColor;
    color: variables.$ErrorForeColor;
    width: 22px;
    height: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.9rem;
    border-radius: 50%;
    @include mixins.trans;

    &__small_cart {
      top: 0;
      right: 0;
    }

    i {
      color: variables.$ErrorForeColor;
    }

    &:hover {
      cursor: pointer;
      transform: rotate(90deg);
    }
  }
}
</style>
